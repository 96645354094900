import React from 'react'
import BookButton from './BookButton'
import { HashLink as Link } from 'react-router-hash-link';

function Coaching({coaching}) {
  return (<div style={{backgroundImage: `url(${coaching.img_url})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
    <div className='text-white lg:mx-auto py-10 w-full xl:w-2/5 lg:border-x-4 lg:border-r-white backdrop-brightness-50' >
      <div className='lg:mx-20 mx-10'>
        <h2 className='text-center font-bold text-xl'>{coaching.name}</h2>
        <p className='text-center mb-5'>{coaching.price}€ / month - Cancel anytime</p>
        <p className='mb-5'>Benefit from a 1-on-1 coaching with me where I'll be directly in touch with you during your fitness journey. My coaching will be adapted to you individually in order to get the best results. Contact me by applying for a FREE call first.</p>
        <ul className='leading-10 mb-10 list-disc'>
          {coaching.descriptions.map((item, index) => {
            return (
              <li key={index} className="ml-10">{item}</li>
            )
          })}
        </ul>
        <Link to="/#contact">
          <BookButton text="Apply now"/>
        </Link>
      </div>
    </div>
  </div>
  )
}

export default Coaching
