import React from 'react'
import backgroundImage from '../../assets/images/backgroundhome.jpg'
// import Button from '../Button'

function LandingPage() {
  return (
    <div className="text-white bg-cover bg-center min-h-screen p-10 md:p-20 lg:p-60 flex flex-col justify-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <h1 className='text-4xl lg:text-5xl w-1/2 lg:w-2/3 mb-10'>Your online personal trainer, whenever and wherever you are.</h1>
      {/* <h3 className='text-xl lg:text-2xl mb-5'>Are you ready to start your fitness journey?</h3>
      <div className='flex flex-col w-full sm:flex-row lg:w-3/5'>
        <a href="#1on1coaching"><Button text="Coaching" /></a>
        <a href="#programs"><Button text="Programs" /></a>
      </div> */}
      <p className='text-lg lg:text-xl lg:w-2/3 justify-center text-white'>You decided to take your fitness journey seriously and want some help? Whether your goal is to build muscle, lose weight or just finding that healthy routine again, I can get you on track.</p>
      <p className='text-lg lg:text-xl lg:w-2/3 justify-center text-white'>My goal is to help you become autonomous so that you can continue your personal journey without me and become the best version of yourself. <a href='/#1on1coaching' className='font-bold underline'>Start here</a></p> 
    </div>
  )
}

export default LandingPage
