import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import LandingPage from '../components/home/LandingPage';
import Intro from '../components/home/Intro';
import trainerKettlebell from '../assets/images/trainer-kettlebell.jpg'
import introFood from '../assets/images/intro-food.jpg'
import Coaching from '../components/home/Coaching'
// import Programme from '../components/home/Programme'
import FAQ from '../components/home/FAQ'
// import BundleProgrammeBeasts from '../components/home/BundleProgrammeBeasts';
import ContactForm from '../components/home/ContactForm'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUp } from '@fortawesome/free-solid-svg-icons'
import sales from '../sellingApi'

function Home() {
  return (
    <div>
      <div className='relative' id="topOfHome">
        <div className='absolute right-0'><Navbar /></div>
        <LandingPage />
      </div>
      <Intro
        location="left"
        img={trainerKettlebell}
        description="Trainer with kettlebells"
        title="Online Personal Training"
        text="You don't have time to go to the gym or don't like training with other people around you? Online personal training allows you to train at home with no or minimal equipment. Plus you have the flexibility to train whenever you want. Arrange your training sessions yourself so that it suits your daily schedule."
        extratext="Gym training sessions are also possible if you prefer. "
        link="More details here!"
        pathname="/#1on1coaching"
      />
      <Intro
        img={introFood}
        description="healthy food"
        title="Nutrition"
        text="Fitness is a combination of training and nutrition. Both deserve your attention on your self improvement journey. Although this can be a complex topic the basics are really simple and if you're honest with yourself most of the time you know what you should and shouldn't eat."
        extratext="I'll make nutrition the easiest part for you but if you still want to have a more detailed look into it, "
        link="click here!"
        pathname="/nutrition"
      />
        <div id="1on1coaching" className='mb-10'>
          {sales.coachings.map((coaching) => (
            <Coaching key={coaching.id + coaching.name} coaching={coaching} />
          ))}
        </div>
        {/* <div id="programs">
          <h2 className='text-center font-bold text-xl pt-10'>PROGRAMS</h2>
          <p className='text-center text-xl pb-10'><em>Programs for every level - The only programs you need!</em></p>
          <div className='flex justify-center mb-20 w-full m-auto'>
            {sales.bundles.map((bundle) => (
              <BundleProgrammeBeasts key={bundle.id + bundle.name} bundle={bundle} />
            ))}
          </div> */}
          {/* <div className='flex overflow-x-scroll'> */}
          {/* <div className='flex flex-col lg:flex-row lg:overflow-x-scroll overflow-y-scroll'> */}
          {/* div className='flex-col lg:flex-row lg:flex-wrap justify-center' */}
            {/* {sales.programmes.map((program) => (
              <Programme key={program.id + program.name} program={program} />
            ))}
          </div> */}
        {/* </div> */}
        <div className='lg:m-auto mx-10 py-5 lg:w-2/3'>
          <h2 className='mb-10 text-xl font-bold text-center'>FREQUENTLY ASKED QUESTIONS</h2>
          <FAQ
            question="I struggle to be consistent with my training and nutrition, what can I do?"
            answer="Consistency is key to get results and maintain them long term. The most important thing in order to be consistent is to enjoy what you do. Forcing yourself to eat or do things you don't like will not cut it. I will help you find your own way to reach your goals while enjoying what you do."
          />
          <FAQ question="I want to lose my abdominal fat, can you help me?"
            answer="There is no magical formula or exercise to lose abdominal fat. Training and adapted nutrition will reduce your overall body fat and therefore in the abdominal region as well. I will help you combine both to put you in the best position to get rid of this belly fat."
          />
          <FAQ question="Do I need to have training experience?"
            answer="No. The 1-on-1 coaching allows me to adapt the training program to you specifically, whether your are a beginner or advanced."
          />
          <FAQ question="What happens on the free call?"
            answer="We will have a short call which we will use to get to know each other, to discuss your goals and how we will work together."
          />
        </div>
        <div id="contact">
          <ContactForm />
        </div>
      <a href="#topOfHome" className='fixed right-10 bottom-24 text-yellow'><FontAwesomeIcon icon={faCircleUp} /></a>
      <Footer />
    </div>
  );
}

export default Home;
