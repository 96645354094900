import React from 'react'
import { useParams } from 'react-router-dom'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import PaypalCoaching from '../components/PaypalCoaching';

function CoachingShow({coachings}) {
  const { slug } = useParams();
  const coaching = coachings.find((c) => c.slug === slug);

  if (!coaching) {
    return <div>Program not found</div>;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className=' mx-10 lg:mx-40 mt-7 lg:mt-14 mb-20 flex-grow'>
        <div className='text-xl text-center mb-7 lg:mb-10'>
          <h2 className='font-bold'>{coaching.name}</h2>
          <p>{coaching.price}€</p>
        </div>
        <div className='lg:flex'>
          <div className='lg:w-1/3 lg:mr-20'>
            <p className='mb-5'>{coaching.fulldescription}</p>
            <p>What is included?</p>
            <ul className='list-disc h-1/2 mb-10'>
              {coaching.descriptions && coaching.descriptions.map((item, index) => (
                <li key={index} className="sm:ml-10">{item}</li>
              ))}
            </ul>
          </div>
          <div className='lg:w-2/3 text-center'>
            <p className='font-bold'>Let  your fitness journey begin!</p>
            <PaypalCoaching
              price={coaching.price}
              itemSelling={coaching.name}
            />
          </div>
        </div>
        <p className='mt-10 text-justify'>You will get an email with a link to google drive through which you can find the program and the video demonstrations that you can download if you like.</p>
        <hr className='h-1 my-20 bg-programme-blue'/>
      </div>
      <Footer/>
    </div>
  )
}

export default CoachingShow
